import * as React from 'react'
import {Button} from "./button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";

const HomeButton: React.FC = () => {
    const navigate = useNavigate()
    return (
        <Button size='icon'
                onClick={() => navigate('/home')}
                className="absolute top-4 left-4 bg-indigo-500 hover:bg-indigo-600 text-white px-4 py-2 rounded-md focus:outline-none"
        >
            <FontAwesomeIcon icon={faHome} size='2x'/>
        </Button>
    )
}

export default HomeButton