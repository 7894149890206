import * as React from "react";
import {useEffect, useState} from "react";
import useCheckLogin from "../lib/CheckLogin";

import {Button} from "../Components/ui/button";
import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle,} from "../Components/ui/card";
import {Input} from "../Components/ui/input";
import {Label} from "../Components/ui/label";
import PasswordInput from "../Components/ui/PasswordInput";
import Spinner from "../Components/ui/Spinner";
import handleLogin from "../lib/Login";
import {useNavigate} from "react-router-dom";

const LoginPage: React.FC = () => {
    const {checkLogin} = useCheckLogin()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true) // handle if the backend checks are complete
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    useEffect(() => {
        const checkUserStatus = async () => {
            const isLoggedIn = await checkLogin();
            if (isLoggedIn) {
                navigate('/home'); // dont allow logged in users to access the login page as it would be useless
            } else (
                setIsLoading(false) // allow the page to load if the user isnt logged in
            )
        }
        checkUserStatus().then(() => {
        })
    }, [checkLogin, navigate]);

    if (isLoading) {
        return <Spinner/>; // Show the spinner while loading
    }

    return (
        <div className="flex items-center justify-center min-h-screen">
            <Card className="w-[350px] bg-slate-800 border-transparent">
                <CardHeader>
                    <CardTitle className="text-white flex items-center justify-center">
                        Login
                    </CardTitle>
                    <CardDescription className="flex items-center justify-center">
                        Please Enter Your Credentials Below.
                    </CardDescription>
                </CardHeader>
                <CardContent>
                    <form onSubmit={handleLogin} id="loginForm">
                        <div className="grid w-full items-center gap-4">
                            <div className="flex flex-col space-y-1.5">
                                <Label className="text-white" htmlFor="username">
                                    Username
                                </Label>
                                <Input
                                    id="username"
                                    name="username" // Ensure this matches the expected field name
                                    placeholder="Enter Your Username Here"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                            <div className="flex flex-col space-y-1.5">
                                <Label className="text-white" htmlFor="password">
                                    Password
                                </Label>
                                <PasswordInput
                                    id="password"
                                    name='password'
                                    placeholder="Enter Your Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                        </div>
                    </form>
                </CardContent>
                <CardFooter className="flex justify-center">
                    <Button type="submit" form="loginForm">
                        Login
                    </Button>
                </CardFooter>
            </Card>
        </div>
    );
};

export default LoginPage;
