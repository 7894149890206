const apiKey: string = 'f2f1e6884c59bf5af48d01965af5576b'

const apiBearer: string = 'eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJmMmYxZTY4ODRjNTliZjVhZjQ4ZDAxOTY1YWY1NTc2YiIsInN1YiI6IjY2NmI1ZWVhZmU0YjExNzc4MmNkMjNlYSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.X5k7W0i09pDWAwLmAYeLv8ozRAfptaQLERcEBuhiB3Y'

export const fetchShowDetails = async (tmdbId: string): Promise<any> => {
    const url = `https://api.themoviedb.org/3/tv/${tmdbId}?api_key=${apiKey}`;

    try {
        const response = await fetch(url);
        if (!response.ok) {
            console.error('Failed to fetch TV show details from TMDB');
        }
        return await response.json(); // i didnt interface this because tbh im not entirely sure of the full array returned
    } catch (error) {
        console.error('Error fetching TV show details:', error);
        return null;
    }
};
export const fetchMovieDetails = async (tmdbId: string): Promise<any> => {
    const url = `https://api.themoviedb.org/3/movie/${tmdbId}?api_key=${apiKey}`;

    try {
        const response = await fetch(url);
        if (!response.ok) {
            console.error('Failed to fetch TV show details from TMDB');
        }
        return await response.json(); // i didnt interface this because tbh im not entirely sure of the full array returned
    } catch (error) {
        console.error('Error fetching TV show details:', error);
        return null;
    }
};
export const fetchSeasonDetails = async (tmdbId: string, seasonNumber: number): Promise<any> => {
    const url = `https://api.themoviedb.org/3/tv/${tmdbId}/season/${seasonNumber}?api_key=${apiKey}`;

    try {
        const response = await fetch(url);
        if (!response.ok) {
            console.error('Failed to fetch season details from TMDB');
        }
        return await response.json(); // again here, not interfaced as idk the scope of return
    } catch (error) {
        console.error('Error fetching season details:', error);
        return null;
    }
};
export const searchTMDB = async (query: string): Promise<any[]> => {
    if (!query.trim()) {
        return [];
    }

    const url = `https://api.themoviedb.org/3/search/multi?include_adult=true&language=en-US&page=1&query=${encodeURIComponent(query)}`;

    const options = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${apiBearer}`
        }
    };

    try {
        const response = await fetch(url, options);
        if (!response.ok) {
            console.error('Network response was not ok');
        }

        const data = await response.json();
        return data.results || []; // api returns 'results' array, if not exist provide blank
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
};

export const fetchIMDBId = async (tmdbId: string, mediaType: 'movie' | 'tv'): Promise<string | null> => {
    // Determine the appropriate endpoint based on the media type
    const endpoint = mediaType === 'movie' ? `movie` : `tv`;
    const url = `https://api.themoviedb.org/3/${endpoint}/${tmdbId}?api_key=${apiKey}&append_to_response=external_ids`; // Replace with your TMDB API key

    try {
        const response = await fetch(url);
        if (!response.ok) {
            console.error('Failed to fetch the details from TMDB');
        }
        const data = await response.json();
        return data.external_ids.imdb_id
    } catch (error) {
        console.error('Error fetching IMDB ID:', error);
        return null;
    }
};