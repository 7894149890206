import {FormEvent} from "react";
import {darkErrorToast, darkSuccessToast} from "./Toaster";

const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent the default form submission behavior

    const form = event.currentTarget;
    const formData = new FormData(form);

    // Fetch user and password from login card
    const username = formData.get('username') as string;
    const password = formData.get('password') as string;

    // Send a POST request to the /api/login endpoint
    try {
        const response = await fetch('https://api.cyzxin.com/api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({username, password}),
            credentials: 'include' // We want cookies to be allowed
        });

        if (response.ok) {
            darkSuccessToast('Log In Successful')
            window.location.href = '/home'
        } else {
            darkErrorToast('Invalid Username Or Password')
        }
    } catch (error) {
        console.error('Error during login:', error);
    }
};

export default handleLogin;
