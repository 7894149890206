import React from 'react';
import {Button} from './button'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGithub} from "@fortawesome/free-brands-svg-icons";

const Footer: React.FC = () => {
    return (
        <footer className="w-full border-t border-gray-700 py-4 bg-transparent bottom-0 left-0 right-0">
            <div className="flex justify-center items-center flex-col gap-8 text-white">
                <Button className="dark:bg-transparent bg-transparent hover:bg-white hover:text-black">
                    <a href="https://github.com/Cyzxin" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faGithub} size="2x"/>
                    </a>
                </Button>
                <div className='text-white font-bold bg-transparent'>This Site Does Not Store Any Files On Its Server.
                    All
                    Content
                    Is Provided By Non-Affiliated Third Parties & All Files Are Already In Online Circulation.
                </div>
            </div>

        </footer>
    );
};

export default Footer;