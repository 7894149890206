import React, {ReactNode} from 'react';
import {FaTimes} from 'react-icons/fa';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children: ReactNode;
}

const Modal: React.FC<ModalProps> = ({isOpen, onClose, title, children}) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            {/* Overlay */}
            <div className="fixed inset-0 bg-black opacity-75" onClick={onClose}></div>

            {/* Modal Content */}
            <div className="relative bg-slate-800 text-white rounded-lg shadow-lg w-[90%] max-w-md p-6 z-10">
                {/* Close Button */}
                <button
                    className="absolute top-2 right-2 text-white hover:text-gray-400"
                    onClick={onClose}
                >
                    <FaTimes/>
                </button>

                <h2 className="text-xl font-semibold mb-4">{title}</h2>
                <div>{children}</div>
            </div>
        </div>
    );
};

export default Modal;
