import React, {useEffect, useState, useRef} from "react";
import useCheckLogin from '../lib/CheckLogin';
import {useNavigate} from "react-router-dom";
import SearchBar from "../Components/ui/SearchBar";
import {searchTMDB} from "../lib/tmdbAPI";
import Spinner from "../Components/ui/Spinner";
import AccountManagement from "../Components/AccountManagement";

const FrontPage: React.FC = () => {
    const {redirectIfNotLoggedIn} = useCheckLogin();
    const [checkingLogin, setCheckingLogin] = useState(true);
    const [query, setQuery] = useState('');
    const [results, setResults] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [recentShows, setRecentShows] = useState<any[]>([]);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const navigate = useNavigate();
    const recentShowsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const checkLogin = async () => {
            await redirectIfNotLoggedIn('/');
            setCheckingLogin(false);
        };
        checkLogin();
    }, [redirectIfNotLoggedIn]);

    useEffect(() => {
        const storedShows = localStorage.getItem('recentShows');
        if (storedShows) {
            setRecentShows(JSON.parse(storedShows));
        }
    }, []);

    const fetchResults = async (searchQuery: string) => {
        setQuery(searchQuery);
        setLoading(true);
        const results = await searchTMDB(searchQuery);
        setResults(results);
        setLoading(false);
    };

    const addShowToHistory = (show: any) => {
        const existingShow = recentShows.find(item => item.id === show.id);
        if (!existingShow) {
            const updatedShows = [
                {
                    ...show,
                    media_type: show.media_type || show.mediaType // Normalize media_type
                },
                ...recentShows.slice(0, 9)
            ]; // Limit to 10 items
            localStorage.setItem('recentShows', JSON.stringify(updatedShows));
            setRecentShows(updatedShows);
        }
    };

    const handleResultClick = async (show: any) => {
        console.log('Navigating to:', show); // Debugging line to see the show details
        addShowToHistory(show);

        if (show.media_type === 'tv') {
            navigate(`/tv/${show.id}`);
        } else if (show.media_type === 'movie') {
            navigate(`/film/${show.id}`);
        }
    };

    const handleScroll = (direction: 'left' | 'right') => {
        if (recentShowsRef.current) {
            const scrollAmount = direction === 'left' ? -200 : 200; // Adjust based on desired scroll step
            recentShowsRef.current.scrollBy({
                left: scrollAmount,
                behavior: 'smooth',
            });

            // Update scroll position after scrolling
            setScrollPosition(recentShowsRef.current.scrollLeft + scrollAmount);
        }
    };

    const handleManualScroll = () => {
        if (recentShowsRef.current) {
            setScrollPosition(recentShowsRef.current.scrollLeft);
        }
    };

    useEffect(() => {
        const currentRef = recentShowsRef.current;
        if (currentRef) {
            currentRef.addEventListener('scroll', handleManualScroll);
            return () => {
                currentRef.removeEventListener('scroll', handleManualScroll);
            };
        }
    }, [recentShowsRef]);

    if (checkingLogin) {
        return <Spinner/>;
    }

    return (
        <>
            <div className="flex flex-col items-center min-h-screen p-4">
                <SearchBar onSearch={fetchResults}/>
                {loading && <p className="mt-4 text-white">Loading...</p>}
                <div
                    className={`mt-4 w-full max-w-lg h-[40vh] ${results.length > 0 ? 'overflow-y-scroll' : 'overflow-hidden'} bg-opacity-50 rounded-lg shadow-inner`}>
                    {results.length > 0 && (
                        <ul className="rounded-lg">
                            {results.map((result, index) => (
                                <li
                                    key={index}
                                    className="flex items-center p-4 border-b border-opacity-50 last:border-b-0 cursor-pointer hover:bg-gray-900 bg-opacity-50"
                                    onClick={() => handleResultClick(result)}
                                >
                                    {result["poster_path"] ? (
                                        <img
                                            src={`https://image.tmdb.org/t/p/w92/${result["poster_path"]}`}
                                            alt={result.title || result.name}
                                            className="w-12 h-18 mr-4 rounded-sm"
                                        />
                                    ) : (
                                        <div className="w-12 h-18 mr-4 bg-gray-300 rounded-sm"/>
                                    )}
                                    <div>
                                        <p className="text-lg font-medium text-white">
                                            {result.title || result.name}
                                        </p>
                                        <p className="text-sm text-gray-300">
                                            {result["media_type"] === 'movie' ? 'Movie' : 'TV Show'}
                                        </p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                {recentShows.length > 0 && (
                    <div className="mt-10 w-full max-w-full px-4">
                        <h2 className="text-xl font-bold mb-2 text-white text-center">Recently Viewed</h2>
                        <div className="relative flex justify-center items-center overflow-hidden"
                             style={{maxWidth: '100%', maxHeight: '250px'}}>
                            {/* Left Arrow Button */}
                            <button
                                onClick={() => handleScroll('left')}
                                className="absolute left-2 bg-white text-black rounded-full p-2 shadow-md z-30 md:hidden"
                                style={{top: '50%', transform: 'translateY(-50%)'}}
                            >
                                ⬅️
                            </button>
                            <div ref={recentShowsRef} className="flex space-x-4 px-4 recent-shows overflow-hidden"
                                 style={{height: '100%', maxWidth: 'calc(100% - 6rem)'}}>
                                {recentShows.slice(0, 10).map((show, index) => (
                                    <div
                                        key={show.id}
                                        className={`relative w-40 md:w-32 lg:w-40 flex-shrink-0 cursor-pointer transition-transform duration-500 ease-in-out transform ${
                                            hoveredIndex === index ? 'scale-105 z-20' : 'scale-95'
                                        } ${hoveredIndex !== null && hoveredIndex !== index ? 'grayscale opacity-60' : 'grayscale-0 opacity-100'}`}
                                        onMouseEnter={() => setHoveredIndex(index)}
                                        onMouseLeave={() => setHoveredIndex(null)}
                                        onClick={() => handleResultClick(show)}
                                        style={{
                                            zIndex: hoveredIndex === index ? 20 : 10 - index,
                                            marginLeft: index === 0 ? 0 : '-60px',
                                            transition: 'transform 0.5s ease, grayscale 0.5s ease, opacity 0.5s ease',
                                        }}
                                    >
                                        <img
                                            src={`https://image.tmdb.org/t/p/w342/${show.poster_path}`}
                                            alt={show.name || show.title}
                                            className="object-cover w-full rounded-lg transition-all duration-500 ease-in-out"
                                            style={{
                                                height: '100%',
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                            {/* Right Arrow Button */}
                            <button
                                onClick={() => handleScroll('right')}
                                className="absolute right-2 bg-white text-black rounded-full p-2 shadow-md z-30 md:hidden"
                                style={{top: '50%', transform: 'translateY(-50%)'}}
                            >
                                ➡️
                            </button>
                        </div>
                    </div>
                )}

            </div>
            <AccountManagement/>
        </>
    );
};

export default FrontPage;
