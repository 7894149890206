import {useNavigate} from 'react-router-dom';

const useCheckLogin = () => {
    const navigate = useNavigate();

    const checkLogin = async (): Promise<boolean> => {
        try {
            const response = await fetch('https://api.cyzxin.com/api/cookies', {
                method: 'GET',
                credentials: 'include', // Include cookies in the request
            });
            return response.ok;
        } catch (error) {
            console.error('Fetch error:', error);
            return false; // Treat any error as not logged in
        }
    };

    const redirectIfNotLoggedIn = async (path: string) => {
        const isLoggedIn = await checkLogin();
        if (!isLoggedIn) {
            navigate(path); // Redirect to given path if not logged in
        }
    };

    return {checkLogin, redirectIfNotLoggedIn};
};

export default useCheckLogin;
