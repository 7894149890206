import React, {useState, useEffect} from 'react';
import {Button} from './ui/button';
import {Label} from './ui/label';
import {Input} from './ui/input';
import Modal from './ui/Modal';
import PasswordInput from "./ui/PasswordInput";
import {Checkbox} from "./ui/checkbox";
import {Popover, PopoverContent, PopoverTrigger} from './ui/popover';
import {Bars3Icon} from '@heroicons/react/24/solid';
import {changePassword, createNewUser} from "../lib/AccountManagement"; // Importing the functions

import {darkErrorToast, darkSuccessToast} from "../lib/Toaster";

const AccountManagement: React.FC = () => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [isCreateAccountModalOpen, setCreateAccountModalOpen] = useState(false);
    const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isCreatingAdmin, setIsCreatingAdmin] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isMobileView, setIsMobileView] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);

    useEffect(() => {
        // Check if the screen is small (mobile view)
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchUserStatus = async () => {
            try {
                const response = await fetch('https://api.cyzxin.com/api/userstatus', {
                    method: 'GET',
                    credentials: 'include', // Include cookies in the request
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user status');
                }

                const data = await response.json();
                setIsAdmin(data.role === 'admin');
            } catch (error) {
                console.error('Error fetching user status:', error);
            }
        };

        fetchUserStatus();
    }, []);

    const handleCreateAccount = async () => {
        try {
            const payload = {username, password, isAdmin: isCreatingAdmin};
            const response = await createNewUser(payload);
            if (response.ok) {
                darkSuccessToast('Account Created')
            } else {
                const errorData = await response.json(); // Parse the error response
                if (errorData?.customError === 'username-taken') {
                    darkErrorToast('Account With That Username Already Exists');
                } else {
                    darkErrorToast('Failed To Create Account');
                }
            }
            // Reset fields
            setUsername('');
            setPassword('');
            setIsCreatingAdmin(false);
            setCreateAccountModalOpen(false);
            setPopoverOpen(false); // Close the popover
        } catch (error) {
            console.error('Error creating account:', error);
            darkErrorToast('An error occurred while creating the account');
        }
    };

    const handleChangePassword = async () => {
        if (newPassword !== confirmPassword) {
            darkErrorToast('New Password And Confirmation Do Not Match.');
            return;
        }
        try {
            const payload = {currentPassword, newPassword};
            const response = await changePassword(payload);
            if (response.ok) {
                darkSuccessToast('Password Changed Successfully');
            } else {
                const errorData = await response.json(); // Parse the error response
                if (errorData?.customError === 'mismatched-passwords') {
                    darkErrorToast('Current Password is Incorrect');
                } else {
                    darkErrorToast('Failed To Change Password');
                }
            }

            // Reset fields
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
            setChangePasswordModalOpen(false);
            setPopoverOpen(false); // Close the popover
        } catch (error) {
            console.error('Error changing password:', error);
            darkErrorToast('An error occurred while changing the password');
        }
    };

    return (
        <>
            {!isMobileView && (
                <div className="fixed top-4 left-4 flex flex-col space-y-2 z-50">
                    <h2 className="text-xl font-bold mb-2 text-white">Account Management</h2>
                    {isAdmin ? (
                        <div className="flex flex-col space-y-2">
                            <Button onClick={() => setCreateAccountModalOpen(true)}>
                                Create New Account
                            </Button>
                            <Button onClick={() => setChangePasswordModalOpen(true)}>
                                Change Password
                            </Button>
                        </div>
                    ) : (
                        <div className="text-center">
                            <Button onClick={() => setChangePasswordModalOpen(true)}>
                                Change Password
                            </Button>
                        </div>
                    )}
                </div>
            )}
            {isMobileView && (
                <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
                    <PopoverTrigger asChild>
                        <Button
                            className="fixed bottom-4 left-4 p-2 bg-slate-700 text-white rounded-full z-50"
                            onClick={() => setPopoverOpen(true)}
                        >
                            <Bars3Icon className="w-6 h-6"/>
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent
                        align="start"
                        className="p-4 bg-slate-800 border-none shadow-lg rounded-lg text-white space-y-2"
                        side="right"
                    >
                        <h2 className="text-xl font-bold">Account Management</h2>
                        <div className="flex flex-col space-y-2">
                            {isAdmin && (
                                <Button
                                    className="w-full"
                                    onClick={() => {
                                        setCreateAccountModalOpen(true);
                                        setPopoverOpen(false); // Close the popover
                                    }}
                                >
                                    Create New Account
                                </Button>
                            )}
                            <Button
                                className="w-full"
                                onClick={() => {
                                    setChangePasswordModalOpen(true);
                                    setPopoverOpen(false); // Close the popover
                                }}
                            >
                                Change Password
                            </Button>
                        </div>
                    </PopoverContent>
                </Popover>
            )}

            {/* Modal for creating a new account */}
            <Modal
                isOpen={isCreateAccountModalOpen}
                onClose={() => setCreateAccountModalOpen(false)}
                title="Create New Account"
            >
                <div className="flex flex-col space-y-4">
                    <Label className="text-gray-300" htmlFor="new-username">Username</Label>
                    <Input
                        id="new-username"
                        name="username"
                        placeholder="Enter Username"
                        value={username}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
                        className="bg-slate-700 text-white border-gray-500"
                    />
                    <Label className="text-gray-300" htmlFor="new-password">Password</Label>
                    <PasswordInput
                        id="new-password"
                        name="password"
                        placeholder="Enter Password"
                        value={password}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                        className="bg-slate-700 text-white border-gray-500" // Custom styles for PasswordInput
                    />
                    <div className="flex items-center space-x-2">
                        <Checkbox
                            id="is-admin"
                            checked={isCreatingAdmin}
                            onCheckedChange={(checked) => setIsCreatingAdmin(checked as boolean)}
                            className="bg-slate-700 text-white border-gray-500"
                        />
                        <Label className="text-gray-300" htmlFor="is-admin">Admin</Label>
                    </div>
                    <Button onClick={handleCreateAccount}>Create Account</Button>
                </div>
            </Modal>

            {/* Modal for changing password */}
            <Modal
                isOpen={isChangePasswordModalOpen}
                onClose={() => setChangePasswordModalOpen(false)}
                title="Change Password"
            >
                <div className="flex flex-col space-y-4">
                    <Label className="text-gray-300" htmlFor="current-password">Current Password</Label>
                    <PasswordInput
                        id="current-password"
                        name="currentPassword"
                        placeholder="Enter Current Password"
                        value={currentPassword}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentPassword(e.target.value)}
                        className="bg-slate-700 text-white border-gray-500" // Custom styles for PasswordInput
                    />
                    <Label className="text-gray-300" htmlFor="new-password">New Password</Label>
                    <PasswordInput
                        id="new-password"
                        name="newPassword"
                        placeholder="Enter New Password"
                        value={newPassword}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)}
                        className="bg-slate-700 text-white border-gray-500" // Custom styles for PasswordInput
                    />
                    <Label className="text-gray-300" htmlFor="confirm-password">Confirm New Password</Label>
                    <PasswordInput
                        id="confirm-password"
                        name="confirmPassword"
                        placeholder="Confirm New Password"
                        value={confirmPassword}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
                        className="bg-slate-700 text-white border-gray-500" // Custom styles for PasswordInput
                    />
                    <Button onClick={handleChangePassword}>Change Password</Button>
                </div>
            </Modal>
        </>
    );
};

export default AccountManagement;
