import React, {useState} from "react";

interface SearchBarProps {
    initialQuery?: string; // Optional initial query value
    onSearch: (query: string) => void; // Function to call on search
}

const SearchBar: React.FC<SearchBarProps> = ({initialQuery = '', onSearch}) => {
    const [query, setQuery] = useState(initialQuery);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newQuery = event.target.value;
        setQuery(newQuery);
        onSearch(newQuery);
    };

    return (
        <div className="w-full max-w-lg mt-10">
            <input
                type="text"
                value={query}
                onChange={handleInputChange}
                placeholder="Search for Movies or TV Shows..."
                className="w-full px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
        </div>
    );
};

export default SearchBar;
