import React, {useEffect, useRef, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {fetchShowDetails, fetchSeasonDetails, fetchIMDBId} from '../lib/tmdbAPI';
import Spinner from "../Components/ui/Spinner";
import HomeButton from '../Components/ui/HomeButton';
import useCheckLogin from "../lib/CheckLogin";

const TvPage: React.FC = () => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const {param} = useParams<{ param: string }>();
    const {redirectIfNotLoggedIn} = useCheckLogin()
    const [checkingLogin, setCheckingLogin] = useState(true);
    const navigate = useNavigate();
    const [showDetails, setShowDetails] = useState<any>(null);
    const [selectedSeason, setSelectedSeason] = useState<number | null>(null);
    const [episodes, setEpisodes] = useState<any[]>([]);
    const [isVideoOpen, setIsVideoOpen] = useState<boolean>(false);
    const [currentVideoUrl, setCurrentVideoUrl] = useState<string | null>(null);
    useEffect(() => {
        const preventNavigation = () => {
            if (iframeRef.current && iframeRef.current.contentWindow) {
                const doc = iframeRef.current.contentDocument;
                if (doc) {
                    doc.addEventListener('click', (event) => {
                        if ((event.target as HTMLAnchorElement).tagName === 'A') {
                            event.preventDefault();
                        }
                    });

                    // Override window.open to prevent opening new tabs
                    iframeRef.current.contentWindow.open = function () {
                        return null;
                    };
                }
            }
        };

        if (iframeRef.current) {
            iframeRef.current.addEventListener('load', preventNavigation);
        }

        return () => {
            if (iframeRef.current) {
                iframeRef.current.removeEventListener('load', preventNavigation);
            }
        };
    }, []);
    useEffect(() => {
        const checkLogin = async () => {
            await redirectIfNotLoggedIn('/');
            setCheckingLogin(false);
        };
        checkLogin().then(() => {
        });
    }, [redirectIfNotLoggedIn]);
    useEffect(() => {
        if (!param) {
            // Navigate back or show an error if param is not defined
            navigate('/home');
            return;
        }

        const getShowDetails = async () => {
            const details = await fetchShowDetails(param);
            setShowDetails(details);
        };

        getShowDetails();


    }, [param, navigate]);

    const handleSeasonClick = async (seasonNumber: number) => {
        if (!param) {
            console.error('TV show ID is not defined.');
            return;
        }
        
        setSelectedSeason(seasonNumber);
        const seasonData = await fetchSeasonDetails(param, seasonNumber);
        setEpisodes(seasonData.episodes);
    };
    const handlePlayClick = async (tmdbId: string, season: number | null, episode: number) => {
        try {
            // Fetch the IMDb ID asynchronously
            const imdbId = await fetchIMDBId(tmdbId, 'tv');
            // Construct the video URL using the fetched IMDb ID
            const videoUrl = `https://vidsrc.xyz/embed/tv/${imdbId}/${season}-${episode}`
            // Set the current video URL and open the video player
            setCurrentVideoUrl(videoUrl);
            setIsVideoOpen(true);
        } catch (error) {
            console.error("Error fetching IMDb ID:", error);
        }
    };

    const closeVideo = () => {
        setCurrentVideoUrl(null);
        setIsVideoOpen(false);
    };

    if (!showDetails || checkingLogin) {
        return <Spinner/>;
    }

    return (
        <div className="flex flex-col items-center min-h-screen text-white relative">
            <div className="w-full max-w-6xl flex flex-col flex-grow p-4">
                {/* Home Button */}
                <HomeButton/>
                {/* Show Poster and Description */}
                <div className="flex flex-col md:flex-row items-center mb-4">
                    {showDetails.poster_path && (
                        <img
                            src={`https://image.tmdb.org/t/p/w342/${showDetails.poster_path}`}
                            alt={showDetails.name}
                            className="w-48 h-auto mb-4 md:mb-0 md:mr-4 rounded-md"
                        />
                    )}
                    <div className="flex flex-col">
                        <h1 className="text-2xl font-bold mb-2">{showDetails.name}</h1>
                        <p className="text-md mb-2">{showDetails.overview}</p>
                        <p className="text-sm"><strong>Number of Seasons:</strong> {showDetails.number_of_seasons}</p>
                        <p className="text-sm"><strong>Number of Episodes:</strong> {showDetails.number_of_episodes}</p>
                    </div>
                </div>
                {/* Seasons List */}
                <div className="flex space-x-2 mb-4 overflow-x-auto">
                    {showDetails.seasons.map((season: any) => (
                        <button
                            key={season.id}
                            onClick={() => handleSeasonClick(season.season_number)}
                            className={`px-4 py-2 rounded-md ${selectedSeason === season.season_number ? 'bg-indigo-800' : 'bg-indigo-500 hover:bg-indigo-400'} focus:outline-none`}
                        >
                            {season.season_number}
                        </button>
                    ))}
                </div>
                {/* Episodes List */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full p-4 overflow-y-auto"
                     style={{maxHeight: 'calc(100vh - 300px)'}}>
                    {episodes.map((episode: any) => (
                        <div key={episode.id}
                             className="bg-gray-800 p-4 rounded-lg shadow-lg flex flex-col md:flex-row md:items-center relative">
                            {episode.still_path ? (
                                <div className="relative w-full md:w-32 h-32 md:flex-shrink-0">
                                    <img
                                        src={`https://image.tmdb.org/t/p/w342/${episode.still_path}`}
                                        alt={episode.name}
                                        className="w-full h-full object-cover rounded-lg"
                                    />
                                    <button
                                        className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-2xl rounded-lg focus:outline-none"
                                        onClick={() => handlePlayClick(showDetails.id, selectedSeason, episode.episode_number)}
                                    >
                                        ▶️
                                    </button>
                                </div>
                            ) : (
                                <div className="w-full md:w-32 h-32 bg-gray-700 mb-4 md:mb-0 md:mr-4 rounded-lg"/>
                            )}
                            <div className="flex flex-col justify-between flex-grow md:ml-4">
                                <h3 className="text-lg font-semibold mb-1 text-white">{episode.episode_number}. {episode.name}</h3>
                                <p className="text-sm text-gray-300 line-clamp-3">{episode.overview}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* Fullscreen Video Player */}
            {isVideoOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 p-10">
                    <div className="relative w-full max-w-4xl p-4 bg-black rounded-lg">
                        <button
                            className="absolute top-0 right-0 m-4 text-white text-2xl"
                            onClick={closeVideo}
                        >
                            ✖️
                        </button>
                        {currentVideoUrl ? (
                            <iframe
                                ref={iframeRef}
                                src={`${currentVideoUrl}`}
                                className="w-full h-96"
                                frameBorder="0"
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                                title="Episode Trailer"
                                referrerPolicy="origin"
                            ></iframe>
                        ) : (
                            <div className="w-full h-96 flex items-center justify-center text-white">Video not
                                available</div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default TvPage;
