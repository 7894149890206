import React, {useEffect, useRef, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {fetchIMDBId, fetchMovieDetails} from '../lib/tmdbAPI';
import Spinner from "../Components/ui/Spinner";
import HomeButton from '../Components/ui/HomeButton';
import useCheckLogin from "../lib/CheckLogin";

const MoviePage: React.FC = () => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const {param} = useParams<{ param: string }>();
    const {redirectIfNotLoggedIn} = useCheckLogin()
    const [checkingLogin, setCheckingLogin] = useState(false)
    const navigate = useNavigate();
    const [movieDetails, setMovieDetails] = useState<any>(null);
    const [currentVideoUrl, setCurrentVideoUrl] = useState<string | null>('');
    useEffect(() => {
        const preventNavigation = () => {
            if (iframeRef.current && iframeRef.current.contentWindow) {
                const doc = iframeRef.current.contentDocument;
                if (doc) {
                    doc.addEventListener('click', (event) => {
                        if ((event.target as HTMLAnchorElement).tagName === 'A') {
                            event.preventDefault();
                        }
                    });

                    // Override window.open to prevent opening new tabs
                    iframeRef.current.contentWindow.open = function () {
                        return null;
                    };
                }
            }
        };

        if (iframeRef.current) {
            iframeRef.current.addEventListener('load', preventNavigation);
        }

        return () => {
            if (iframeRef.current) {
                iframeRef.current.removeEventListener('load', preventNavigation);
            }
        };
    }, []);
    useEffect(() => {
        const checkLogin = async () => {
            await redirectIfNotLoggedIn('/');
            setCheckingLogin(false);
        };
        checkLogin().then(() => {
        });
    }, [redirectIfNotLoggedIn]);
    useEffect(() => {
        if (!param) {
            navigate('/home');
            return;
        }

        const getMovieDetails = async () => {
            const details = await fetchMovieDetails(param);
            setMovieDetails(details);

            const imdbId = await fetchIMDBId(param, 'movie')
            const videoUrl: string = `https://vidsrc.xyz/embed/movie/${imdbId}`
            setCurrentVideoUrl(videoUrl);
        };

        getMovieDetails().then(() => {
        });
    }, [param, navigate]);


    if (!movieDetails || checkingLogin) {
        return <Spinner/>;
    }

    return (
        <div
            className="flex flex-col items-center min-h-screen text-white relative p-4 bg-gradient-to-b from-purple-900 to-black">
            <div className="w-full max-w-6xl flex flex-col p-4">
                {/* Home Button */}
                <HomeButton/>
                {/* Movie Poster and Description */}
                <div className="flex flex-col md:flex-row items-center md:items-start mb-4">
                    {movieDetails.poster_path && (
                        <img
                            src={`https://image.tmdb.org/t/p/w342/${movieDetails.poster_path}`}
                            alt={movieDetails.title}
                            className="w-48 md:w-64 h-auto mb-4 md:mb-0 md:mr-4 rounded-md"
                        />
                    )}
                    <div className="flex flex-col text-center md:text-left">
                        <h1 className="text-2xl font-bold mb-2">{movieDetails.title}</h1>
                        <p className="text-xs md:text-md lg:text-lg mb-2">{movieDetails.overview}</p>
                        <p className="text-sm"><strong>Runtime:</strong> {movieDetails.runtime} minutes</p>
                        <p className="text-sm"><strong>Release
                            Date:</strong> {new Date(movieDetails.release_date).toDateString()}</p>
                    </div>
                </div>
                {/* Iframe for Video Player */}
                {currentVideoUrl && (
                    <div className="flex justify-center mt-8 w-full">
                        <iframe
                            ref={iframeRef}
                            src={`${currentVideoUrl}`}
                            className="w-full max-w-4xl h-60 md:h-96 rounded-lg"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Movie Trailer"
                            referrerPolicy="origin"
                        ></iframe>
                    </div>
                )}
            </div>
        </div>
    );

};

export default MoviePage;
