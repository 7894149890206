import toast from 'react-hot-toast';

export const darkErrorToast = (message: string) => {
    toast(message, {
        icon: '❌',
        style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
        },
    });
}

export const darkSuccessToast = (message: string) => {
    toast(message, {
        icon: '✅',
        style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
        },
    });
}
