// lib/accountManagement.ts

export interface CreateUserPayload {
    username: string;
    password: string;
    isAdmin: boolean;
}

export interface ChangePasswordPayload {
    currentPassword: string;
    newPassword: string;
}

export const createNewUser = async (payload: CreateUserPayload): Promise<Response> => {
    try {
        const response = await fetch('https://api.cyzxin.com/api/createuser', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include', // Include cookies in the request
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            console.error('Failed to create new user');
        }

        return response;
    } catch (error) {
        console.error('Error creating new user:', error);
        throw error;
    }
};


export const changePassword = async (payload: ChangePasswordPayload): Promise<Response> => {
    try {
        return await fetch('https://api.cyzxin.com/api/changepassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include', // Include cookies in the request
            body: JSON.stringify(payload),
        });
    } catch (error) {
        console.error('Error changing password:', error);
        throw error;
    }
};

