import React, {useState} from 'react';
import {Input} from './input';
import {EyeIcon} from '@heroicons/react/24/outline';

interface PasswordInputProps {
    id: string;
    placeholder: string;
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string; // Optional className prop
}

const PasswordInput: React.FC<PasswordInputProps> = ({
                                                         id,
                                                         placeholder,
                                                         name,
                                                         value,
                                                         onChange,
                                                         className = '', // Default empty string if no className is provided
                                                     }) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    return (
        <div className={`relative w-full ${className}`}>
            <Input
                id={id}
                name={name}
                type={isPasswordVisible ? 'text' : 'password'}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                className={`w-full pr-10 ${className}`} // Apply custom className
            />
            <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-600 focus:outline-none"
            >
                <EyeIcon className="w-6 h-6"/>
            </button>
        </div>
    );
};

export default PasswordInput;
