import React from 'react';
import {Link} from 'react-router-dom';

const HomePage: React.FC = () => {
    return (
        <div className="w-full min-h-screen flex flex-col items-center justify-center">
            <div className="container max-w-md px-4 py-12 text-center">
                <div className="mb-8">
                    <h1 className="text-4xl font-bold text-white">Welcome, Please Login To Continue</h1>
                </div>
                <Link
                    to="/login"
                    className="inline-flex items-center justify-center h-10 px-6 font-medium text-white bg-purple-600 rounded-md hover:bg-purple-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500 focus-visible:ring-offset-2"
                >
                    Login
                </Link>
            </div>
        </div>
    );
};

export default HomePage;